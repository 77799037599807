@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap);

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

[dir] .fade-enter {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in, -webkit-transform 270ms ease-in;
  transition: opacity 250ms ease-in, transform 270ms ease-in;
  transition: opacity 250ms ease-in, transform 270ms ease-in, -webkit-transform 270ms ease-in;
}

[dir] .fade-enter.fade-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}


@import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'


html {
    scroll-behavior: smooth;
}
body {
    font-family: "Cairo", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
    font-weight: 500;
    overflow-x: hidden !important;
}
[dir] body {
    margin: 0;
    background: #f3f7f8;
}


button, a {
    outline: none !important;
    color: #ff3008;
    text-decoration: none !important;
}
p {
    font-size: 13px;
    color: #7a7e8a;
}
[dir=ltr] p {
    text-align: left;
}
[dir=rtl] p {
    text-align: right;
}
img{
    image-rendering: auto;
}
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    color: #000000;
}
[dir=ltr] .h1, [dir=ltr] .h2, [dir=ltr] .h3, [dir=ltr] .h4, [dir=ltr] .h5, [dir=ltr] .h6, [dir=ltr] h1, [dir=ltr] h2, [dir=ltr] h3, [dir=ltr] h4, [dir=ltr] h5, [dir=ltr] h6 {
    text-align: left;
}
[dir=rtl] .h1, [dir=rtl] .h2, [dir=rtl] .h3, [dir=rtl] .h4, [dir=rtl] .h5, [dir=rtl] .h6, [dir=rtl] h1, [dir=rtl] h2, [dir=rtl] h3, [dir=rtl] h4, [dir=rtl] h5, [dir=rtl] h6 {
    text-align: right;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
[dir] input::-webkit-outer-spin-button, [dir] input::-webkit-inner-spin-button {
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Nav */
.osahan-nav {
    z-index: 9;
}
[dir] .osahan-nav {
    padding: 0;
}
[dir=ltr] .osahan-nav {
    background: #fff none repeat scroll 0 0 !important;
    background: var(--header_bg_color) none repeat scroll 0 0 !important;
}
[dir=rtl] .osahan-nav {
    background: #fff none repeat scroll 100% 0 !important;
    background: var(--header_bg_color) none repeat scroll 100% 0 !important;
}
.navbar-light .navbar-nav .nav-link{
    color: var(--header_text_color) !important;
}
.osahan-nav .nav-link {
    font-weight: 700;
    font-size: 15px;
}
[dir] .osahan-nav .nav-link {
    margin: 0;
    padding: 28px 16px !important;
}

@media (min-width: 991.98px) {
    .osahan-nav .nav-link:hover {
        color: var(--header_bg_color) !important;
    }
    [dir] .osahan-nav .nav-link:hover {
        background-color: #fff;
    }
}


[dir=rtl] [dir=ltr] .osahan-nav .nav-link:first-child{
    margin: 0 !important;
}

[dir=rtl] [dir=ltr] .dropdown-cart.nav-item.dropdown {
    //margin-right: 28px;
}

[dir] .dropdown-cart.nav-item.dropdown a .fa {
    margin: 0 10px;
}
[dir] .dropdown-cart.nav-item.dropdown a:after {
    margin: 0 10px;
}

[dir] .osahan-nav .nav-link.active {
    border-bottom: 4px solid #ddd;
}

[dir] .osahan-nav .nav-link.active + .nav-link.active {
    border-bottom-width: 0px;
}

[dir] .dropdown-cart .dropdown-menu {
    padding-top: 0px !important;
}
.nav-osahan-pic {
    width: 32px;
    height: 32px;
    position: relative;
    vertical-align: text-top;
}
[dir] .nav-osahan-pic {
    border: 3px solid #fff;
    box-shadow: 0px 0px 3px #ccc;
}
[dir=ltr] .nav-osahan-pic {
    margin: -8px 5px -6px 0;
}
[dir=rtl] .nav-osahan-pic {
    margin: -8px 0 -6px 5px;
}
.dropdown-cart-top {
    min-width: 340px;
}
[dir] .dropdown-cart-top {
    border-top: 2px solid #ff3008;
}

.header {
    position: fixed;
    z-index: 9999;
    width: 100%;
}
.dropdown-cart-top-header {
    min-height: 107px;
}
.dropdown-cart-top-header .img-fluid {
    height: 59px;
    width: 59px;
}
[dir] .dropdown-cart-top-header .img-fluid {
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    padding: 3px;
}
[dir=ltr] .dropdown-cart-top-header .img-fluid {
    float: left;
}
[dir=rtl] .dropdown-cart-top-header .img-fluid {
    float: right;
}
.dropdown-cart-top-header p.text-secondary {
    font-size: 11px;
    line-height: 24px;
    font-weight: 600;
}
.dropdown-cart-top-header h6 {
    font-size: 14px;
}
.food-item {
    display: inline-block;
    font-size: 6px;
    height: 13px;
    line-height: 12px;
    width: 13px;
}
[dir] .food-item {
    border: 1px solid;
    border-radius: 2px;
    text-align: center;
}
[dir=ltr] .dropdown-cart-top-body .food-item {
    float: left;
    margin: 4px 10px 0 0;
    padding-left: 1px;
}
[dir=rtl] .dropdown-cart-top-body .food-item {
    float: right;
    margin: 4px 0 0 10px;
    padding-right: 1px;
}
/* login/Register */
:root {
    --input-padding-x: 1.5rem;
    --input-padding-y: 0.75rem;
}
.login,
.image {
    min-height: 100vh;
}
[dir] .bg-image {
    background-image: url('/img/login-bg.png');
    background-size: cover;
    background-position: center;
}
.login-heading {
    font-weight: 300;
}
.btn-login {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
}
[dir] .btn-login {
    padding: 0.75rem 1rem;
    border-radius: 2rem;
}
.form-label-group {
    position: relative;
}
[dir] .form-label-group {
    margin-bottom: 1rem;
}
.form-label-group>input,
.form-label-group>label {
    height: 51px;
}
[dir] .form-label-group>input, [dir] .form-label-group>label {
    padding: 15px 0;
    border-radius: 0px;
    background: transparent !important;
    border: none;
    border-bottom: 1px solid #ced4da;
    box-shadow: none !important;
}
.form-label-group>label {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    /* Match the input under the label */
    transition: all .1s ease-in-out;
}
[dir] .form-label-group>label {
    margin-bottom: 0;
    cursor: text;
    border: 1px solid transparent;
    border-radius: .25rem;
}
[dir=ltr] .form-label-group>label {
    left: 0;
}
[dir=rtl] .form-label-group>label {
    right: 0;
}
.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}
.form-label-group input:-ms-input-placeholder {
    color: transparent;
}
.form-label-group input::placeholder {
    color: transparent;
}
[dir] .form-label-group input:not(:-ms-input-placeholder) {
    padding-top: calc(0.75rem + 0.75rem * (2 / 3));
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(0.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
}
[dir] .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(0.75rem + 0.75rem * (2 / 3));
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(0.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
}
.form-label-group input:not(:-ms-input-placeholder)~label {
    font-size: 12px;
    color: #777;
}
.form-label-group input:not(:placeholder-shown)~label {
    font-size: 12px;
    color: #777;
}
[dir] .form-label-group input:not(:-ms-input-placeholder)~label {
    padding-top: calc(0.75rem / 3);
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(0.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
}
[dir] .form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(0.75rem / 3);
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(0.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
}
.btn-google {
    color: white;
}
[dir] .btn-google {
    background-color: #ea4335;
}
.btn-facebook {
    color: white;
}
[dir] .btn-facebook {
    background-color: #3b5998;
}
/* Homepage Search Block */
.homepage-search-block {
    min-height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
[dir] .homepage-search-block {
    background-color: var(--home_bg_color);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.homepage-search-block.homepage-search-block--small{
    min-height: 0;
    min-height: initial;
    z-index: 9;
}
[dir] .homepage-search-block.homepage-search-block--small{
    padding: 116px 0;
}
.homepage-search-block.homepage-search-block--small .search-box-map #search-box-map{
    height: 50px;
    visibility: hidden;
}

.homepage-search-block .banner-overlay{
    position: absolute;
    bottom: 0;
    top: 0;
    opacity: 0.6;
    -webkit-backdrop-filter: saturate(180%) blur(5px);
    backdrop-filter: saturate(180%) blur(5px);
}

[dir] .homepage-search-block .banner-overlay{
    background: #000;
}

[dir=ltr] .homepage-search-block .banner-overlay{
    left: 0;
    right: 0;
}

[dir=rtl] .homepage-search-block .banner-overlay{
    right: 0;
    left: 0;
}

.homepage-search-block .map-overlay{
    position: absolute;
    bottom: 0;
    top: 0;
    opacity: 0.9;
    -webkit-backdrop-filter: saturate(180%) blur(5px);
    backdrop-filter: saturate(180%) blur(5px);
}

[dir] .homepage-search-block .map-overlay{
    background: #000;
}

[dir=ltr] .homepage-search-block .map-overlay{
    left: 0;
    right: 0;
}

[dir=rtl] .homepage-search-block .map-overlay{
    right: 0;
    left: 0;
}

.homepage-search-block  #search-box-map{
    width: 100%;
    height: 350px;
}

.homepage-search-block #map-overlay-box{
    width: 100%;
}

.homepage-search-title h1, .homepage-search-title h5{
    color: var(--home_text_color)
}

 .homepage-search-form + h6{
    color: #e1e1e1
}

.homepage-search-title h5{
    color: var(--home_text_color)
}
.homepage-search-slider-item{
    //width: 326px;
    //height: 326px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage-search-slider-item > div{
    position: absolute;
    top: 0;
    bottom: 0;
    -webkit-filter: blur(16px);
            filter: blur(16px);
}

[dir=ltr] .homepage-search-slider-item > div{
    right: 0;
    left: 0;
}

[dir=rtl] .homepage-search-slider-item > div{
    left: 0;
    right: 0;
}

.homepage-search-slider-item > a{
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage-search-form .form-control {
    font-size: 15px;
    height: 50px;
}

[dir=ltr] .homepage-search-form .form-control {
    padding-right: 106px;
}

[dir=rtl] .homepage-search-form .form-control {
    padding-left: 106px;
}
.homepage-search-form .form-group {
    position: relative;
}
[dir=ltr] .homepage-search-form .form-group {
    text-align: left;
}
[dir=rtl] .homepage-search-form .form-group {
    text-align: right;
}
[dir=ltr] .select2-container{
    text-align: left;
}
[dir=rtl] .select2-container{
    text-align: right;
}
[dir=ltr] .select2-container .select2-search input{
    text-align: left;
}
[dir=rtl] .select2-container .select2-search input{
    text-align: right;
}
.homepage-search-form .locate-me {
    color: #666;
    font-size: 12px;
    position: absolute;
    top: 9px;
}
[dir] .homepage-search-form .locate-me {
    padding: 7px 10px;
    border-radius: 3px;
}
[dir=ltr] .homepage-search-form .locate-me {
    background: #ececec none repeat scroll 0 0;
    right: 14px;
}
[dir=rtl] .homepage-search-form .locate-me {
    background: #ececec none repeat scroll 100% 0;
    left: 14px;
}
.homepage-search-form .locate-me:hover {
    color: #ffffff;
}
[dir=ltr] .homepage-search-form .locate-me:hover {
    background: #666 none repeat scroll 0 0;
}
[dir=rtl] .homepage-search-form .locate-me:hover {
    background: #666 none repeat scroll 100% 0;
}
.homepage-search-form .btn {
    font-size: 15px;
    font-weight: 600;
    height: 50px;
}
[dir] .homepage-search-form .btn {
    padding: 13px 0px;
}


.homepage-search-form .location-dropdown i {
    position: absolute;
    top: 20px;
    z-index: 1;
}


[dir=ltr] .homepage-search-form .location-dropdown i {
    left: 18px;
}


[dir=rtl] .homepage-search-form .location-dropdown i {
    right: 18px;
}
.homepage-search-form .select2-selection {
    height: 50px !important;
    outline: none !important;
}
[dir] .homepage-search-form .select2-selection {
    border-color: #ced4da !important;
    border-radius: 0.25rem !important;
}
.homepage-search-form .select2-selection__rendered {
    font-size: 15px;
    line-height: 47px !important;
}
[dir=ltr] .homepage-search-form .select2-selection__rendered {
    padding: 0 28px 0 31px !important;
}
[dir=rtl] .homepage-search-form .select2-selection__rendered {
    padding: 0 31px 0 28px !important;
}

[dir=ltr] .homepage-search-form .col-lg-7 .select2-selection__rendered {
    padding: 0 28px 0 14px !important;
}

[dir=rtl] .homepage-search-form .col-lg-7 .select2-selection__rendered {
    padding: 0 14px 0 28px !important;
}

.location-dropdown .select2-selection__arrow{
    display: none;
}

.homepage-search-form .select2-selection__arrow {
    top: 13px !important;
}

[dir=ltr] .homepage-search-form .select2-selection__arrow {
    right: 9px !important;
}

[dir=rtl] .homepage-search-form .select2-selection__arrow {
    left: 9px !important;
}
[dir] .select2-dropdown{
    margin: -2px 0 0 0;
    border: 1px solid #ced4da;
    border-radius: 3px;
}
[dir] .select2-container--default .select2-search--dropdown .select2-search__field{
   border: 1px solid #ced4da;
}
.select2-search__field{
    outline: none !important;
}
[dir] .select2-search__field{
    border: 1px solid #ced4da;
}
.owl-carousel {
    position: relative;
}

[dir] .owl-theme .owl-nav [class*="owl-"] {
    position: absolute;
    bottom: calc(50% - 20px);
    background: #fff none repeat scroll 0 0 !important;
    border-radius: 30px !important;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    color: #000 !important;
    height: 40px;
    line-height: 40px;
    margin: 0 !important;
    text-align: center;
    text-transform: uppercase;
    width: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
[dir] .owl-theme .owl-nav [class*="owl-"] i {
    font-size: 13px !important;
    font-weight:normal !important;
}
[dir] .owl-theme .owl-nav .owl-prev {
    margin-left: -16px !important;
}
[dir] .owl-theme .owl-nav .owl-next {
    right:0px;
    margin-right: -16px !important;
}
/* Homepage */
[dir] .owl-carousel-four .item {
    padding: 6px 7px;
}
.osahan-category-item {
    overflow: hidden;
}
[dir] .osahan-category-item {
    background: #fff;
    margin: 5px 2px;
    border-radius: 3px;
    text-align: center;
    padding: 17px 7px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    border: 1px solid #fff;
}
[dir] .osahan-category-item:hover {
    border: 1px solid #ff3008;
}
.osahan-category-item h6 {
    font-size: 15px;
    color: var(--button_color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
[dir] .osahan-category-item h6 {
    margin: 0px;
    text-align: center;
}
.osahan-category-item p {
    font-size: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
}
[dir] .osahan-category-item p {
    text-align: center;
    margin: 0px;
}

.osahan-category-item img {
    width: 88px !important;
    height: 88px;
    object-fit: cover;
    display: inline-block !important;
}

[dir] .osahan-category-item img {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    margin: 2px 0 8px 0;
}
.line {
    display: inline-table;
    height: 4px;
    width: 50px;
}
[dir] .line {
    background-color: #5e5e5e;
    border-radius: 12px;
    margin: 0 0 51px;
}
/* Customize Bootstrap */
.small, small {
    font-size: 85%;
    font-weight: 600;
}
.select2, .select2 span {
    width: 100% !important;
}
[dir=ltr] .select2-container--default .select2-selection--single .select2-selection__arrow b {
    left: 96%;
}
[dir=rtl] .select2-container--default .select2-selection--single .select2-selection__arrow b {
    right: 96%;
}
[dir] .select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 3px;
}

[dir] .footer-2{
    padding-top: 55px !important;
    background-color: transparent;
}

[dir=ltr] .footer-2{
    margin-right: 27%;
}

[dir=rtl] .footer-2{
    margin-left: 27%;
}

@media (max-width: 1200px) {
    [dir=ltr] .footer-2{
        margin-right: 0 !important;
    }
    [dir=rtl] .footer-2{
        margin-left: 0 !important;
    }
}

.footer-2 p{
    font-size: 20px;
    color: #fff;
}

.follow-us-btn{
    width: 35px;
    height: 35px;
    display: inline-block;
    font-size: 20px;
    color: #3f51b5;
    line-height: 35px;
}

[dir] .follow-us-btn{
    margin: 5px;
    background-color: #dbdbdb;
    border-radius: 50px;
}
.modal-footer{
    justify-content: space-between;
}

.modal-footer .btn {
    font-size: 14px;
}

[dir] .modal-footer .btn {
    padding: 12px 16px;
}
[dir] .btn-success, [dir] .badge-success {
    background-color: #3ecf8e !important;
    border-color: #3ecf8e !important;
}
.btn-outline-success {
    color: #3ecf8e !important;
}
[dir] .btn-outline-success {
    border-color: #3ecf8e !important;
}
.btn-outline-success:hover {
    color: #ffffff !important;
}
[dir] .btn-outline-success:hover {
    border-color: #3ecf8e !important;
    background-color: #3ecf8e !important;
}
.text-success {
    color: #3ecf8e !important;
}
[dir] .border-success {
    border-color: #3ecf8e!important;
}
[dir] .btn-danger, [dir] .badge-danger {
    background-color: #f32129 !important;
    border-color: #f32129 !important;
}
.btn-outline-danger {
    color: #f32129 !important;
}
[dir] .btn-outline-danger {
    border-color: #f32129 !important;
}
.btn-outline-danger:hover {
    color: #ffffff !important;
}
[dir] .btn-outline-danger:hover {
    border-color: #f32129 !important;
    background-color: #f32129 !important;
}
.text-danger {
    color: #f32129 !important;
}
[dir] .border-danger {
    border-color: #f32129 !important;
}
[dir] .btn-primary, [dir] .badge-danger {
    background-color: var(--color_gradients_1) !important;
    border-color: var(--color_gradients_1) !important;
}
.btn-outline-primary {
    color: var(--color_gradients_1)8 !important;
}
[dir] .btn-outline-primary {
    border-color:var(--color_gradients_1) !important;
}
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #ffffff !important;
}
[dir] .btn-outline-primary:hover, [dir] .btn-outline-primary:not(:disabled):not(.disabled).active, [dir] .btn-outline-primary:not(:disabled):not(.disabled):active, [dir] .show>.btn-outline-primary.dropdown-toggle {
    border-color: var(--color_gradients_1) !important;
}
[dir=ltr] .btn-outline-primary:hover, [dir=ltr] .btn-outline-primary:not(:disabled):not(.disabled).active, [dir=ltr] .btn-outline-primary:not(:disabled):not(.disabled):active, [dir=ltr] .show>.btn-outline-primary.dropdown-toggle {
    background: linear-gradient(135deg, var(--color_gradients_1) 1%,var(--color_gradients_2) 100%);
}
[dir=rtl] .btn-outline-primary:hover, [dir=rtl] .btn-outline-primary:not(:disabled):not(.disabled).active, [dir=rtl] .btn-outline-primary:not(:disabled):not(.disabled):active, [dir=rtl] .show>.btn-outline-primary.dropdown-toggle {
    background: linear-gradient(-135deg, var(--color_gradients_1) 1%,var(--color_gradients_2) 100%);
}
.text-primary {
    color: var(--color_gradients_1) !important;
}
[dir] .border-primary {
    border-color: var(--color_gradients_1)!important;
}
.btn-lg {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
}
[dir] .btn-lg {
    padding: 14px 35px;
}
[dir] .modal-content {
    border: none;
    border-radius: 6px;
}
[dir] .btn {
    border-radius: 6px !important;
}
.form-control {
    font-size: 13px;
    min-height: 38px;
}
[dir] .form-control {
    border-radius: 2px;
}
[dir] .card {
    border-radius: 7px;
}
[dir] .rounded {
    border-radius: 7px !important;
}
[dir] .input-group-text {
    border-radius: 2px;
}
[dir] .custom-checkbox .custom-control-label::before {
    border-radius: 2px;
}
[dir] .nav-pills .nav-link {
    border-radius: 2px;
}
[dir] .alert, [dir] .badge, [dir] .dropdown-menu {
    border-radius: 2px;
}
.search-box-map {
    width: 100%
}

.homepage-search-block .map-overlay #search-box-map, .homepage-search-block .map-overlay #map-overlay-box{
    width: 100%;
    height: 380px;
}

@media (max-width: 768px) {
    .search-box-map {
        position: relative;
    }
}

[dir] .input-group-sm>.custom-select, [dir] .input-group-sm>.form-control, [dir] .input-group-sm>.input-group-append>.btn, [dir] .input-group-sm>.input-group-append>.input-group-text, [dir] .input-group-sm>.input-group-prepend>.btn, [dir] .input-group-sm>.input-group-prepend>.input-group-text {
    border-radius: 2px;
}
[dir] .bg-dark {
    background-color: #000 !important;
}
[dir] .pb-5, [dir] .py-5 {
    padding-bottom: 4rem !important;
}
[dir] .pt-5, [dir] .py-5 {
    padding-top: 4rem !important;
}
.dropdown-menu {
    font-size: 13px;
}
.dropdown-item {
    color: rgba(0,0,0,.5);
    font-weight: 500;
}
[dir] .dropdown-item {
    border-radius: 0 !important;
    padding: 7px 18px;
}

.dropdown-item span.countries-item {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}

[dir=ltr] .dropdown-item span.countries-item {
    float: right;
}

[dir=rtl] .dropdown-item span.countries-item {
    float: left;
}

[dir=ltr] [aria-labelledby=countries] a{
    float: left;
}

[dir=rtl] [aria-labelledby=countries] a{
    float: right;
}

.dropdown-item:focus {
    color: #fff;
}

[dir] .dropdown-item:focus {
    background-color: #ff3008;
}
/* Listing */
[dir] .list-cate-page {
    margin: -5px 0px 0px 0px;
}
[dir] .filters-card-body {
    padding: 18px 0 0 0;
}
.filters-search {
    position: relative;
}
.filters-search i {
    position: absolute;
    top: 13px;
}
[dir=ltr] .filters-search i {
    left: 12px;
}
[dir=rtl] .filters-search i {
    right: 12px;
}
[dir=ltr] .filters-search .form-control {
    padding-left: 34px;
}
[dir=rtl] .filters-search .form-control {
    padding-right: 34px;
}
[dir] .custom-checkbox label.custom-control-label, [dir] .custom-radio label.custom-control-label {
    padding-top: 2px;
    cursor: pointer;
}
.filters-card-header a {
    font-weight: 500;
    width: 100%;
    display: block;
}
[dir=ltr] .filters-card-header a i {
    margin: 2px -2px 0 0;
}
[dir=rtl] .filters-card-header a i {
    margin: 2px 0 0 -2px;
}
.position-absolute {
    z-index: 1;
}
iframe.position-absolute {
    z-index: 0;
}

.contact-us-map.position-absolute {
    z-index: 0;
}

.list-card .count-number {
    display: inline-block;
}

[dir] .list-card .count-number {
    margin: 4px 0 0 0;
}
.list-card a {
    color:inherit;
}
.list-card a:hover {
    color:#0091bc;
}
.list-card .star {
    bottom: 8px;
}
[dir=ltr] .list-card .star {
    right: 8px;
}
[dir=rtl] .list-card .star {
    left: 8px;
}
.list-card .star .badge {
    font-size: 11px;
}
[dir] .list-card .star .badge {
    padding: 5px 5px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
}
.list-card .member-plan {
    top: 8px;
}
[dir=ltr] .list-card .member-plan {
    left: 8px;
}
[dir=rtl] .list-card .member-plan {
    right: 8px;
}
.list-card .member-plan .badge {
    font-size: 11px;
}
[dir] .list-card .member-plan .badge {
    padding: 5px 5px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
}
.list-card .list-card-image {
    position: relative;
}
.list-card .favourite-heart {
    top: 8px;
}
[dir] .list-card .favourite-heart {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
}
[dir=ltr] .list-card .favourite-heart {
    right: 8px;
}
[dir=rtl] .list-card .favourite-heart {
    left: 8px;
}
.list-card .favourite-heart i {
    width: 23px;
    height: 23px;
    display: inline-block;
    font-size: 15px;
    line-height: 24px;
}
[dir] .list-card .favourite-heart i {
    background: #fff;
    text-align: center;
    border-radius: 50px;
}
/* detail */
.count-number .btn {
    font-size: 12px;
}
[dir] .count-number .btn {
    padding: 2px 5px;
    border-radius: 0px;
}

[dir] .modal-footer .count-number .btn {
    padding: 12px 16px;
}
.modal-footer .count-number-input{
    width: 50px;
    height: 44px;
    font-size: 18px;
}

[dir] .restaurant-detailed-header-left {
    text-shadow: 0px 0px 10px #000;
}
.count-number-input {
    width: 24px;
    color: #fff;
    height: 24px;
    vertical-align: bottom;
}
[dir] .count-number-input {
    text-align: center;
    margin: 0 -4px;
    background: #6c757d;
    border: none;
    border-radius: 0px;
}
.generator-bg { /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color_gradients_1)', endColorstr='var(--color_gradients_2)',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
[dir] .generator-bg {
    background: var(--color_gradients_1);
}
[dir=ltr] .generator-bg {
    background: linear-gradient(135deg, var(--color_gradients_1) 1%,var(--color_gradients_2) 100%);
}
[dir=rtl] .generator-bg {
    background: linear-gradient(-135deg, var(--color_gradients_1) 1%,var(--color_gradients_2) 100%);
}

.generator-bg2 { /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eaeaea',GradientType=1 );
}

[dir] .generator-bg2 {
    background: #ffffff;
}

[dir=ltr] .generator-bg2 {
    background: linear-gradient(135deg, #ffffff 1%,#eaeaea 100%);
}

[dir=rtl] .generator-bg2 {
    background: linear-gradient(-135deg, #ffffff 1%,#eaeaea 100%);
}

.restaurant-detailed-banner {
    position: relative;
}
.restaurant-detailed-banner .img-fluid.cover {
    height: 424px;
    object-fit: cover;
}
.restaurant-detailed-header {
    bottom: 0;
    position: absolute;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#d4000000', GradientType=0);
}
[dir] .restaurant-detailed-header {
    padding: 65px 0 17px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
}
[dir=ltr] .restaurant-detailed-header {
    left: 0;
    right: 0;
}
[dir=rtl] .restaurant-detailed-header {
    right: 0;
    left: 0;
}
[dir] .homepage-great-deals-carousel .item img {
    border-radius: 2px;
}
.restaurant-detailed-earn-pts .img-fluid.float-left {
    height: 61px;
}
.card-icon-overlap {
    overflow: hidden;
    position: relative;
}
.red-generator-bg {
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff3371', endColorstr='#fe6753', GradientType=1);
}
[dir] .red-generator-bg {
    background: #ff3371;
}
[dir=ltr] .red-generator-bg {
    background: linear-gradient(135deg, #ff3371 1%, #fe6753 100%);
}
[dir=rtl] .red-generator-bg {
    background: linear-gradient(-135deg, #ff3371 1%, #fe6753 100%);
}
.border-btn {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
}
[dir] .border-btn {
    border: 1px solid #dee0e6;
    border-radius: 2px;
    padding: 8px 15px;
    margin: 0 0 10px 0;
}
.restaurant-detailed-ratings .generator-bg {
    display: inline-block;
    font-size: 12px;
    height: 20px;
}
[dir] .restaurant-detailed-ratings .generator-bg {
    border-radius: 4px;
    padding: 3px 6px;
}
[dir=ltr] .restaurant-detailed-ratings .generator-bg {
    margin: 0 4px 0 0;
}
[dir=rtl] .restaurant-detailed-ratings .generator-bg {
    margin: 0 0 0 4px;
}
.explore-outlets-search .form-control {
    font-size: 15px;
    height: 50px;
}
[dir] .explore-outlets-search .form-control {
    border-radius: 2px !important;
    padding: 5px 20px;
}
.explore-outlets-search .btn {
    color: #777777;
    font-size: 15px;
    position: absolute;
    z-index: 9;
    text-decoration: none;
}
[dir] .explore-outlets-search .btn {
    padding: 13px 17px;
}
[dir=ltr] .explore-outlets-search .btn {
    right: 0;
}
[dir=rtl] .explore-outlets-search .btn {
    left: 0;
}
.mall-category-item {
    overflow: hidden;
}
[dir] .mall-category-item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin: 0 6px;
    text-align: center;
    padding: 0 0 7px 0;
}
.mall-category-item img {
    height: 89px;
    object-fit: cover;
    width: 100%;
}
.mall-category-item h6 {
    font-size: 14px;
    font-weight: 500;
}
[dir] .mall-category-item h6 {
    margin: 0;
    padding: 10px 10px 3px 10px;
}
.mall-category-item .btn {
    font-size: 12px;
    top: 7px;
}
[dir] .mall-category-item .btn {
    padding: 2px 8px;
}
[dir=ltr] .mall-category-item .btn {
    right: 7px;
}
[dir=rtl] .mall-category-item .btn {
    left: 7px;
}
.mall-category-item small {
    color: #f32129;
    font-size: 10px;
}
[dir] .mall-category-item small {
    background: #ececec;
    padding: 1px 6px;
    border-radius: 2px;
}
.mall-category-item:hover small {
    color: #ffffff;
}
[dir] .mall-category-item:hover small {
    background: #f32129;
}
.address-map {
    overflow: hidden;
}
[dir] .address-map {
    border-radius: 2px;
}
.new-arrivals-card .card-img .new-arrivals {
    bottom: 11px;
    position: absolute;
}
[dir=ltr] .new-arrivals-card .card-img .new-arrivals {
    left: 12px;
}
[dir=rtl] .new-arrivals-card .card-img .new-arrivals {
    right: 12px;
}
.new-arrivals {
    color: #fff;
    text-transform: uppercase;
}
[dir] .new-arrivals {
    border-radius: 2px;
    padding: 1px 13px;
}
[dir=ltr] .new-arrivals {
    background: #000 none repeat scroll 0 0;
}
[dir=rtl] .new-arrivals {
    background: #000 none repeat scroll 100% 0;
}
.new-arrivals-card .card-img {
    position: relative;
}
.total-like-user {
    height: 34px;
    width: 34px;
}
[dir] .total-like-user {
    border: 2px solid #fff;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
}
.total-like-user-main a {
    display: inline-block;
}
[dir=ltr] .total-like-user-main a {
    margin: 0 -17px 0 0;
}
[dir=rtl] .total-like-user-main a {
    margin: 0 0 0 -17px;
}
.total-like {
    display: inline-block;
    font-weight: 500;
    height: 34px;
    line-height: 33px;
    vertical-align: top;
}
[dir] .total-like {
    border: 1px solid;
    border-radius: 50px;
    padding: 0 13px;
}
[dir] .restaurant-detailed-ratings-and-reviews hr {
    margin: 0 -24px;
}
[dir] .star-rating {
    cursor:pointer;
}
.graph-star-rating-header .star-rating {
    font-size: 17px;
}
.progress {
    height: 30px;
}
[dir] .progress {
    border-radius: 0;
}
[dir=ltr] .progress {
    background: #f2f4f8 none repeat scroll 0 0;
}
[dir=rtl] .progress {
    background: #f2f4f8 none repeat scroll 100% 0;
}
.rating-list {
    display: inline-flex;
    width: 100%;
}
[dir] .rating-list {
    margin-bottom: 15px;
}
.rating-list-left {
    height: 16px;
    line-height: 29px;
    width: 10%;
}
.rating-list-center {
    width: 80%;
}
.rating-list-right {
    line-height: 29px;
    width: 10%;
}
[dir=ltr] .rating-list-right {
    text-align: right;
}
[dir=rtl] .rating-list-right {
    text-align: left;
}
.restaurant-slider-pics {
    bottom: 0;
    font-size: 12px;
    z-index: 999;
}
[dir] .restaurant-slider-pics {
    padding: 0 10px;
}
[dir=ltr] .restaurant-slider-pics {
    left: 0;
}
[dir=rtl] .restaurant-slider-pics {
    right: 0;
}
.restaurant-slider-view-all {
    bottom: 15px;
    z-index: 999;
}
[dir=ltr] .restaurant-slider-view-all {
    right: 15px;
}
[dir=rtl] .restaurant-slider-view-all {
    left: 15px;
}
.offer-dedicated-nav .nav-link.active,
.offer-dedicated-nav .nav-link:hover,
.offer-dedicated-nav .nav-link:focus {
    color: #3868fb;
}
[dir] .offer-dedicated-nav .nav-link.active, [dir] .offer-dedicated-nav .nav-link:hover, [dir] .offer-dedicated-nav .nav-link:focus {
    border-color: #3868fb;
}
.offer-dedicated-nav .nav-link {
    color: #000000;
    font-weight: 600;
}
[dir] .offer-dedicated-nav .nav-link {
    border-bottom: 2px solid #fff;
    padding: 16px 0;
}
[dir=ltr] .offer-dedicated-nav .nav-item {
    margin: 0 37px 0 0;
}
[dir=rtl] .offer-dedicated-nav .nav-item {
    margin: 0 0 0 37px;
}
[dir] .restaurant-detailed-action-btn {
    margin-top: 12px;
}
.restaurant-detailed-header-right .btn-success {
    height: 45px;
    min-width: 130px;
}
[dir] .restaurant-detailed-header-right .btn-success {
    border-radius: 3px;
    margin: -18px 0 18px;
    padding: 7px;
}
.text-black {
    color: #000000;
}
.icon-overlap {
    bottom: -23px;
    font-size: 74px;
    opacity: 0.23;
    position: absolute;
}
[dir=ltr] .icon-overlap {
    right: -32px;
}
[dir=rtl] .icon-overlap {
    left: -32px;
}
.menu-list img {
    width: 71px;
    height: 71px;
    object-fit: cover;
    transition: 0.3s;
}

[dir=ltr] .menu-list:hover img {
    -webkit-transform: scale(1.1) rotate(-2deg);
            transform: scale(1.1) rotate(-2deg);
}

[dir=rtl] .menu-list:hover img {
    -webkit-transform: scale(1.1) rotate(2deg);
            transform: scale(1.1) rotate(2deg);
}

.menu-list button {
    transition: 0.3s;
}

.menu-list:hover button {
    color: #fff;
}

[dir] .menu-list:hover button {
    background-color: var(--button_color);
    border: 1px solid var(--button_color);
}

.menu-list .media {
    align-items: center;
    overflow: hidden;
}

.restaurant-detailed-header-left img {
    width: 88px;
    height: 88px;
    object-fit: cover;
}

[dir] .restaurant-detailed-header-left img {
    border-radius: 3px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
}
/* Checkout Page */
.addresses-item a.btn.btn-sm {
    min-width: 157px;
}

.addresses-item {
    display: flex;
    flex-direction: revert;
    justify-content: space-between;
}

[dir] .addresses-item {
    cursor: pointer;
}

.addresses-item .gold-members{
    width: 100%;
}

.addresses-item .addresses-item-check {
    width: 6%;
}

[dir] .addresses-item .addresses-item-check {
    padding-top: 36px;
}

.addresses-item .addresses-item-check i{
    font-size: 26px;
    color: #ddd;
    display: block;
}

[dir] .addresses-item .addresses-item-check i{
    text-align: center;
}

.addresses-item .addresses-item-check.selected i{
    color: #3ecf8e;
}

.osahan-cart-item-profile img {
    width: 40px;
    height: 40px;
}

[dir] .osahan-cart-item-profile img {
    border: 3px solid #fff;
}
.osahan-cart-item-profile p {
    font-size: 10px;
}
.osahan-payment .nav-link {
    font-weight: 600;
    color: #535665;
}
[dir] .osahan-payment .nav-link {
    padding: 18px 20px;
    border: none;
}
.osahan-payment .nav-link i {
    width: 28px;
    height: 28px;
    display: inline-block;
    line-height: 29px;
    font-size: 15px;
    color: #fff;
}
[dir] .osahan-payment .nav-link i {
    background: #535665;
    text-align: center;
    border-radius: 50px;
}
[dir=ltr] .osahan-payment .nav-link i {
    margin: 0 7px 0 0px;
}
[dir=rtl] .osahan-payment .nav-link i {
    margin: 0 0px 0 7px;
}
[dir=ltr] .osahan-payment .nav-link{
    text-align: left;
}
[dir=rtl] .osahan-payment .nav-link{
    text-align: right;
}
.osahan-payment .nav-link.active {
    color: #282c3f !important;
}
[dir] .osahan-payment .nav-link.active {
    background: #f3f7f8;
}
[dir] .osahan-payment .nav-link.active i {
    background: #282c3f !important;
}
[dir] .osahan-payment .tab-content {
    background: #f3f7f8;
    padding: 20px;
}
.osahan-card i {
    font-size: 35px;
    vertical-align: middle;
    color: #6c757d;
}
[dir=ltr] .osahan-card {
    margin: 0 0 0 7px;
}
[dir=rtl] .osahan-card {
    margin: 0 7px 0 0;
}
.osahan-payment label {
    font-size: 12px;
    font-weight: 600;
}
[dir] .osahan-payment label {
    margin: 0 0 3px 0;
}
/* My Account */
.payments-item img.mr-3 {
    width: 47px;
}
.order-list .btn {
    min-width: 121px;
    font-size: 13px;
}
[dir] .order-list .btn {
    border-radius: 2px;
    padding: 7px 0 7px 0;
}
.osahan-account-page-left .nav-link {
    font-weight: 600;
    color: #535665;
}
[dir] .osahan-account-page-left .nav-link {
    padding: 18px 20px;
    border: none;
}
.osahan-account-page-left .nav-link i {
    width: 28px;
    height: 28px;
    display: inline-block;
    line-height: 29px;
    font-size: 15px;
    color: #fff;
}
[dir] .osahan-account-page-left .nav-link i {
    background: #535665;
    text-align: center;
    border-radius: 50px;
}
[dir=ltr] .osahan-account-page-left .nav-link i {
    margin: 0 7px 0 0px;
}
[dir=rtl] .osahan-account-page-left .nav-link i {
    margin: 0 0px 0 7px;
}
.osahan-account-page-left .nav-link.active {
    color: #282c3f !important;
}
[dir] .osahan-account-page-left .nav-link.active {
    background: #f3f7f8;
}
[dir] .osahan-account-page-left .nav-link.active i {
    background: #282c3f !important;
}
[dir] .osahan-user-media *{
    text-align: center;
}
.osahan-user-media img {
    width: 90px;
}
[dir] .card offer-card h5.card-title {
    border: 2px dotted #000;
}
.card.offer-card h5 {
    display: inline-table;
    color: #17a2b8;
    font-size: 15px;
    position: relative;
}
[dir] .card.offer-card h5 {
    border: 1px dotted #daceb7;
    margin: 0 0 19px 0;
    border-radius: 2px;
    background: #fffae6;
}
[dir=ltr] .card.offer-card h5 {
    padding: 6px 10px 6px 6px;
}
[dir=rtl] .card.offer-card h5 {
    padding: 6px 6px 6px 10px;
}
.card.offer-card h5 img {
    height: 22px;
    object-fit: cover;
    width: 22px;
}
[dir] .card.offer-card h5 img {
    border-radius: 2px;
}
[dir=ltr] .card.offer-card h5 img {
    margin: 0 8px 0 0;
}
[dir=rtl] .card.offer-card h5 img {
    margin: 0 0 0 8px;
}
.card.offer-card h5:after {
    content: "";
    position: absolute;
    bottom: 0;
}
[dir] .card.offer-card h5:after {
    border-bottom: 4px solid #daceb7;
}
[dir=ltr] .card.offer-card h5:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    left: 30px;
}
[dir=rtl] .card.offer-card h5:after {
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    right: 30px;
}
.card.offer-card h5:before {
    content: "";
    position: absolute;
    top: 0;
}
[dir] .card.offer-card h5:before {
    border-top: 4px solid #daceb7;
}
[dir=ltr] .card.offer-card h5:before {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    left: 30px;
}
[dir=rtl] .card.offer-card h5:before {
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    right: 30px;
}
.card.offer-card .card-btn {
    font-size:13px;
    color:#ff3008;
    vertical-align: top !important;
}
[dir] .card.offer-card .card-btn {
    border:0px !important;
}
.card.offer-card .card-btn:hover {
    color:#0056b3;
}
.payments-item .media {
    align-items: center;
}
[dir=ltr] .payments-item .media img {
    margin: 0 40px 0 11px !important;
}
[dir=rtl] .payments-item .media img {
    margin: 0 11px 0 40px !important;
}
.reviews-members .media .mr-3 {
    width: 56px;
    height: 56px;
    object-fit: cover;
}
.order-list img.mr-4 {
    width: 70px;
    height: 70px;
    object-fit: cover;
}
[dir] .order-list img.mr-4 {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
    border-radius: 2px;
}
[dir=ltr] .order-list img.mr-4 {
    margin-right: 1.5rem !important;
    margin-left:  inherit !important;
}
[dir=rtl] .order-list img.mr-4 {
    margin-left: 1.5rem !important;
    margin-right:  inherit !important;
}
[dir=ltr] .osahan-cart-item p.text-gray.float-right {
    margin: 3px 0 0 0;
    font-size: 12px;
}

[dir=rtl] .osahan-cart-item p.text-gray.float-right {
    margin: 3px 14px 0 0;
    font-size: 12px;
}
.osahan-cart-item .food-item {
    vertical-align: bottom;
}
/* Track List Page */

.osahan-track-order-page {
    min-height: 676px;
}
.osahan-point {
    z-index: 1;
    width: 50%;
    pointer-events: none;
}
[dir] .osahan-point {
    border-radius: 50%;
    padding-bottom: 50%;
    background-color: rgba(225, 48, 8, .2);
    border: 1px solid #ff3008;
}
/* Footer */
[dir] .footer {
    background: #EDF1F4;
}
[dir] footer *{
    text-align: center;
}
.footer h6 {
    font-size: 14px;
    text-transform: uppercase;
}
[dir] .footer ul {
    margin: 0;
    padding: 0;
}
.footer ul li {
    line-height: 26px;
    list-style: outside none none;
}
.footer ul li a {
    color: #6c8293;
}
.search-links a {
    color: #666;
    font-size: 12px;
    line-height: 23px;
}
[dir] .search-links a {
    margin: 0 2px;
}
.search-links {
    font-size: 11px;
}
[dir] footer {
    background-color: #f0f4f7;
}
.product-modal-header{
    overflow: hidden;
    position: relative;
}
.product-slider{
    width: 500px;
    max-width: 100%;
}
[dir] .product-slider .owl-nav{
    margin-top: 0 !important;
}
.product-slider-item{
    width: 500px;
    max-width: 100%;
}
.product-slider-item a{
    width: 500px;
    max-width: 100%;
}
.product-slider-item img{
    width: 500px;
    max-width: 100%;
}
.product-title{
    color: #fff;
}
[dir] .product-title{
    background-color: var(--header_bg_color);
    padding: 10px 22px;
    margin-bottom: -1px;
}

.product-modal .product-info{
    color: #fff;
}

[dir] .product-modal .product-info{
    background-color: var(--header_bg_color);
    padding: 10px 22px;
}

.product-modal .product-info > span{
    display: block;
    font-size: 15px;
    width: 100%;
}

[dir] .product-modal .product-info > span{
    margin-top: 7px;
    border-top: 1px solid #cacaca;
    padding-top: 8px;
}

[dir=ltr] .product-modal .product-info > span{
    float: left;
}

[dir=rtl] .product-modal .product-info > span{
    float: right;
}

[dir=ltr] .product-modal .product-info > span span{
    margin-right: 6px;
}

[dir=rtl] .product-modal .product-info > span span{
    margin-left: 6px;
}

.checkbox-row{
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

[dir] .checkbox-row{
    padding: 7px 13px;
    border-bottom: 1px solid #ececec;
    background-color: #f9f7f7;
    margin-bottom: 0;
}
.checkbox-row .form-text{
    font-size: 14px;
}
[dir] .checkbox-row .form-text{
    margin-top: 0;
}
[dir=ltr] .form-check {
    padding-left: 1.25rem;
    padding-right: 0;
}
[dir=rtl] .form-check {
    padding-right: 1.25rem;
    padding-left: 0;
}
[dir=ltr] .form-check-input {
    margin-left: -1.25rem;
    margin-right: 0;
}
[dir=rtl] .form-check-input {
    margin-right: -1.25rem;
    margin-left: 0;
}
.breadcrumb-osahan h1, .breadcrumb-osahan h6 {
    color:  var(--color_gradients_2) !important;
}
[dir] .breadcrumb-osahan h1, [dir] .breadcrumb-osahan h6 {
    text-shadow: 0px 1px 2px #ffffff94;
}

@media (max-width: 575.98px) {

.m-none{
    display: none;
}
[dir] .homepage-search-title {
    text-align: center;
}
[dir] .list-cate-page {
    margin: 22px 0 0 0;
}
.d-none-m{
  display:none;
}
[dir] .row.pt-2.pb-2 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}
[dir] .row.pt-2 {
    padding-top: 0px !important;
}
[dir=ltr] .offer-card-horizontal .col-md-4.col-4.p-4 {
        padding: 0 0 0 15px !important;
}
[dir=rtl] .offer-card-horizontal .col-md-4.col-4.p-4 {
    padding: 0 15px 0 0 !important;
}
[dir] .row.mt-4.pt-2 {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
[dir] .homepage-add-section {
    padding: 0 13px;
}
.breadcrumb-osahan h1 {
    font-size: 34px;
}
.breadcrumb-osahan h6 {
    font-size: 15px;
}
[dir=ltr] .breadcrumb-osahan {
        padding-left: 15px;
        padding-right: 15px;
}
[dir=rtl] .breadcrumb-osahan {
    padding-right: 15px;
    padding-left: 15px;
}
.reviews-members .media .mr-3 {
    width: 36px;
    height: 36px;
}
.total-like {
    font-size: 12px;
}
.restaurant-detailed-action-btn.float-right .btn {
    font-size: 13px;
}
[dir] .restaurant-detailed-action-btn.float-right .btn {
    margin: 3px 0px;
}
[dir] .login  .col-md-9.col-lg-8.mx-auto.pl-5.pr-5 {
    padding: 0 11px !important;
}
[dir] .login.d-flex.align-items-center.py-5 {
    padding: 0 !important;
}
.h5, h5 {
    font-size: 16px;
}
[dir] .homepage-add-section .col-md-3.col-6 {
    padding: 2px 2px;
}
[dir] .homepage-ad .item {
    padding: 0 2px;
}
.osahan-nav {
    max-height: 100vh;
}
[dir] .osahan-nav {
    padding: 5px 15px;
}
.navbar-light .navbar-brand{
    width: auto !important;
}
[dir] .content.main-content{
    padding-top: 85px !important;
}

[dir] .navbar-toggler {
    padding: 5px 8px;
    border-radius: 2px;
}
[dir] .osahan-nav .nav-link {
    margin: 0px;
    text-align: center;
    padding: 14px 0 !important;
    border-bottom: 1px solid #eef0ef;
}
[dir] .osahan-nav .dropdown-item {
    text-align: center;
}
[dir=ltr] .osahan-slider {
        padding: 18px 0 0 0px !important;
}
[dir=rtl] .osahan-slider {
    padding: 18px 0px 0 0 !important;
}
    [dir=ltr] .osahan-slider {
        padding: 18px 0 0 0px !important;
    }
    [dir=rtl] .osahan-slider {
        padding: 18px 0px 0 0 !important;
    }
    [dir] .product-slider {
        padding: 0px !important;
    }
[dir] .pt-5, [dir] .py-5 {
    padding-top: 3rem !important;
}
[dir] .pb-5, [dir] .py-5 {
    padding-bottom: 3rem !important;
}
.homepage-search-block {
    position: relative;
}
[dir] .homepage-search-block {
    padding: 3rem 0 !important;
}
.mobile-none{
    display: none;
}
[dir] .card {
    margin-bottom: 15px !important;
}
p{
    font-size: 12px;
}
[dir] .restaurant-detailed-header-left img {
    float: none !important;
    margin: 0 0 14px 0 !important;
}
[dir] .restaurant-detailed-header-left {
    text-align: center;
}
[dir] .restaurant-detailed-header-right .btn-success {
    margin-top: 18px;
}
[dir] .restaurant-detailed-header-right.text-right {
    text-align: center !important;
}
.restaurant-detailed-action-btn.float-right {
    width: 100% !important;
    display: block;
}
[dir] .restaurant-detailed-action-btn.float-right {
    float: none !important;
    margin: auto;
    text-align: center;
    padding: 12px 0;
    border-bottom: 1px solid #dedede;
}
[dir] .offer-dedicated-nav {
    text-align: center;
}
.offer-dedicated-nav .nav-item {
    flex: auto;
}
[dir] .offer-dedicated-nav .nav-item {
    border-bottom: 1px solid #ccc;
    padding: 0;
    margin: 0 11px !important;
}
[dir] .offer-dedicated-nav {
    text-align: center;
}
[dir] .address-map.float-right.ml-5 {
    float: none !important;
    margin: 0 0 20px 0 !important;
}
.address-map.float-right.ml-5 iframe, .contact-us-map {
    width: 100%;
}

[dir] .osahan-track-order-detail p.text-gray.mb-5 {
    margin: 1px 0 11px 0 !important;
}

[dir=ltr] .osahan-account-page-left .nav-tabs {
        padding-left: 0px !important;
}

[dir=rtl] .osahan-account-page-left .nav-tabs {
    padding-right: 0px !important;
}
.osahan-account-page-left {
    height: auto !important;
}
[dir] .osahan-account-page-left {
    margin: 0 0 15px 0;
}
[dir] .order-list .float-right {
    float: none !important;
}
[dir] .row.mb-4 {
    margin-bottom: 0px !important;
}
[dir] .app {
    margin-bottom: 26px;
}
.footer ul li a {
    font-size: 12px;
}
.footer h6 {
    font-size: 13px;
    text-transform: capitalize;
}
[dir=ltr] .osahan-payment .col-sm-4.pr-0 {
        padding-right: 15px !important;
}
[dir=rtl] .osahan-payment .col-sm-4.pr-0 {
    padding-left: 15px !important;
}
[dir] .osahan-payment .col-sm-8.pl-0 {
    margin: 14px 0 0 0;
}
[dir=ltr] .osahan-payment .col-sm-8.pl-0 {
        padding-left: 15px !important;
}
[dir=rtl] .osahan-payment .col-sm-8.pl-0 {
    padding-right: 15px !important;
}
[dir] .p-5.osahan-invoice.bg-white.shadow-sm {
    padding: 15px !important;
}
.h3, h3 {
    font-size: 22px;
}
[dir] .p-5 {
    padding: 20px !important;
}
[dir] .osahan-account-page-right {
    padding: 0px !important;
    border: none;
    background: transparent !important;
    box-shadow: none !important;
    margin-top: 15px;
}
}
/* Small devices (landscape phones, 576px and up) */
 @media (min-width: 576px) and (max-width: 767.98px) {
[dir] .homepage-add-section .col-md-3.col-6 {
    padding: 2px 2px;
}
[dir] .homepage-search-title {
    text-align: center;
}
[dir] .row.pt-2.pb-2 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}
[dir] .row.pt-2 {
    padding-top: 0px !important;
}
.d-none-m{
  display:none;
}
[dir] .list-cate-page {
    margin: 22px 0 0 0;
}
[dir] .row.mt-4.pt-2 {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
[dir=ltr] .offer-card-horizontal .col-md-4.col-4.p-4 {
        padding: 0 0 0 15px !important;
}
[dir=rtl] .offer-card-horizontal .col-md-4.col-4.p-4 {
    padding: 0 15px 0 0 !important;
}
[dir] .homepage-add-section {
    padding: 0 13px;
}
[dir] .homepage-ad .item {
    padding: 0 2px;
}
.container {
    max-width: 100%;
}
.osahan-nav {
    overflow-y: visible;
    max-height: 100vh;
}
[dir] .osahan-nav {
    padding: 15px 15px;
}
[dir] .navbar-toggler {
    padding: 5px 8px;
    border-radius: 2px;
}
[dir] .osahan-nav .nav-link {
    margin: 0px;
    text-align: center;
    padding: 14px 0 !important;
    border-bottom: 1px solid #eef0ef;
}
[dir] .osahan-nav .dropdown-item {
    text-align: center;
}
     [dir=ltr] .osahan-slider {
        padding: 18px 0 0 0px !important;
     }
     [dir=rtl] .osahan-slider {
         padding: 18px 0px 0 0 !important;
     }
     [dir] .product-slider {
         padding: 0px !important;
     }
[dir] .pt-5, [dir] .py-5 {
    padding-top: 3rem !important;
}
[dir] .pb-5, [dir] .py-5 {
    padding-bottom: 3rem !important;
}
[dir] .homepage-search-block {
    padding: 3rem 0 !important;
}
.mobile-none{
    display: none;
}
[dir] .card {
    margin-bottom: 15px !important;
}
p{
    font-size: 12px;
}
[dir] .restaurant-detailed-header-left img {
    float: none !important;
    margin: 0 0 14px 0 !important;
}
[dir] .restaurant-detailed-header-left {
    text-align: center;
}
[dir] .restaurant-detailed-header-right .btn-success {
    margin-top: 18px;
}
[dir] .restaurant-detailed-header-right.text-right {
    text-align: center !important;
}
.restaurant-detailed-action-btn.float-right {
    width: 100% !important;
    display: block;
}
[dir] .restaurant-detailed-action-btn.float-right {
    float: none !important;
    margin: auto;
    text-align: center;
    padding: 12px 0;
    border-bottom: 1px solid #dedede;
}
[dir] .offer-dedicated-nav {
    text-align: center;
}
.offer-dedicated-nav .nav-item {
    flex: auto;
}
[dir] .offer-dedicated-nav .nav-item {
    border-bottom: 1px solid #ccc;
    padding: 0;
    margin: 0 11px !important;
}
[dir] .offer-dedicated-nav {
    text-align: center;
}
[dir] .address-map.float-right.ml-5 {
    float: none !important;
    margin: 0 0 20px 0 !important;
}
.address-map.float-right.ml-5 iframe, .contact-us-map {
    width: 100%;
}
.osahan-payment .nav-link i {
    display: block;
}
[dir] .osahan-payment .nav-link i {
    margin: 0 auto 10px auto;
}
[dir] .osahan-payment .nav-link {
    text-align: center;
}
[dir] .osahan-track-order-detail p.text-gray.mb-5 {
    margin: 1px 0 11px 0 !important;
}
[dir=ltr] .osahan-account-page-left .nav-tabs {
        padding-left: 0px !important;
}
[dir=rtl] .osahan-account-page-left .nav-tabs {
    padding-right: 0px !important;
}
.osahan-account-page-left {height: auto !important;}
[dir] .osahan-account-page-left {margin: 0 0 15px 0;}
[dir] .order-list .float-right {
    float: none !important;
}
[dir] .row.mb-4 {
    margin-bottom: 0px !important;
}
[dir] .app {
    margin-bottom: 26px;
}
.footer ul li a {
    font-size: 12px;
}
.footer h6 {
    font-size: 13px;
    text-transform: capitalize;
}
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
.container {
    max-width: 100%;
}
.osahan-nav {
    overflow-y: visible;
    max-height: 100vh;
}
[dir] .osahan-nav {
    padding: 15px 15px;
}
[dir] .navbar-toggler {
    padding: 5px 8px;
    border-radius: 2px;
}
[dir] .osahan-nav .nav-link {
    margin: 0px;
    text-align: center;
    padding: 14px 0 !important;
    border-bottom: 1px solid #eef0ef;
}
[dir] .osahan-nav .dropdown-item {
    text-align: center;
}
    [dir=ltr] .osahan-slider {
        padding: 18px 0 0 0px !important;
    }
    [dir=rtl] .osahan-slider {
        padding: 18px 0px 0 0 !important;
    }
    [dir] .product-slider {
        padding: 0px !important;
    }
[dir] .pt-5, [dir] .py-5 {
    padding-top: 3rem !important;
}
[dir] .pb-5, [dir] .py-5 {
    padding-bottom: 3rem !important;
}
[dir] .homepage-search-block {
    padding: 3rem 0 !important;
}
[dir] .card {
    margin-bottom: 15px !important;
}
p{
    font-size: 12px;
}
[dir] .restaurant-detailed-header-left img {
    float: none !important;
    margin: 0 0 14px 0 !important;
}
[dir] .restaurant-detailed-header-left {
    text-align: center;
}
[dir] .restaurant-detailed-header-right .btn-success {
    margin-top: 18px;
}
[dir] .restaurant-detailed-header-right.text-right {
    text-align: center !important;
}
.restaurant-detailed-action-btn.float-right {
    width: 100% !important;
    display: block;
}
[dir] .restaurant-detailed-action-btn.float-right {
    float: none !important;
    margin: auto;
    text-align: center;
    padding: 12px 0;
    border-bottom: 1px solid #dedede;
}
[dir] .offer-dedicated-nav {
    text-align: center;
}
[dir] .offer-dedicated-nav .nav-item {
    margin: 0 8px !important;
}
[dir] .offer-dedicated-nav {
    text-align: center;
}
[dir] .address-map.float-right.ml-5 {
    float: none !important;
    margin: 0 0 20px 0 !important;
}
.address-map.float-right.ml-5 iframe, .contact-us-map {
    width: 100%;
}
.osahan-payment .nav-link i {
    display: block;
}
[dir] .osahan-payment .nav-link i {
    margin: 0 auto 10px auto;
}
[dir] .osahan-payment .nav-link {
    text-align: center;
}
[dir] .osahan-track-order-detail p.text-gray.mb-5 {
    margin: 1px 0 11px 0 !important;
}
[dir=ltr] .osahan-account-page-left .nav-tabs {
        padding-left: 0px !important;
}
[dir=rtl] .osahan-account-page-left .nav-tabs {
    padding-right: 0px !important;
}
.osahan-account-page-left {
    height: auto !important;
}
[dir] .osahan-account-page-left {
    margin: 0 0 15px 0;
}
[dir] .order-list .float-right {
    float: none !important;
}
[dir] .row.mb-4 {
    margin-bottom: 0px !important;
}
[dir] .app {
    margin-bottom: 26px;
}
.footer ul li a {
    font-size: 12px;
}
.footer h6 {
    font-size: 13px;
    text-transform: capitalize;
}
}
/* Large devices (desktops, 992px and up) */
 @media (min-width: 992px) and (max-width: 1199.98px) {
     .container {
         max-width: 100%;
    }
}
/* Extra large devices (large desktops, 1200px and up) */
 @media (min-width: 1200px) {
}


#loading-wrap {position: fixed; top: 0; bottom: 0; overflow: hidden;
    width: 100vw;
    height: 100vh;
    z-index: 99999;}


[dir=ltr] #loading-wrap {
    left: 0;
    right: 0;}


[dir=rtl] #loading-wrap { right: 0; left: 0;}
#loading-wrap canvas {position: fixed; top: 50%; width: 500px; height: 500px; -outline: 1px solid #fff;}
[dir=ltr] #loading-wrap canvas {
    left: 50%;
    margin: -250px 0 0 -250px;}
[dir=rtl] #loading-wrap canvas { right: 50%; margin: -250px -250px 0 0;}



.navbar-light .navbar-brand{
    height: 75px;
    width: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
}



[dir=ltr] .navbar-light .navbar-brand{
    margin-right: 0 !important;
}



[dir=rtl] .navbar-light .navbar-brand{
    margin-left: 0 !important;
}
.navbar-light .navbar-brand img{
    max-height: 51px;
    max-width: 150px;

}
.btn-primary{
    color: var(--button_color_text) !important;
}
[dir] .btn-primary{
    background-color: var(--button_color) !important;
    border-color: var(--button_color) !important;
}



[dir=ltr] .float-right {
    float: right!important;
}



[dir=rtl] .float-right {
    float: left!important;
}
[dir=ltr] .mr-3, [dir=ltr] .mx-3 {
    margin-right: 1rem!important;
}
[dir=rtl] .mr-3, [dir=rtl] .mx-3 {
    margin-left: 1rem!important;
}

.btn-toolbar{
    width: 100%;
}

[dir=ltr] label.form-label{
    float: left;
}

[dir=rtl] label.form-label{
    float: right;
}
.checkbox-row.form-group{
    width: 100%;
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1340px;
    }
}


.categories-list ul{
    list-style: none;
}


[dir] .categories-list ul{
    margin: 0;
}


.categories-list ul li a{
    color: #6d6d6d;
    font-size: 19px;
}


[dir] .categories-list ul li a{
    padding: 7px 17px !important;
    border-bottom: 1px solid #ff4b150f;
}


[dir=ltr] .categories-list ul li a{
    text-align: left;
}


[dir=rtl] .categories-list ul li a{
    text-align: right;
}

.categories-list ul li a.active{
    color: #fff; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color_gradients_1)', endColorstr='var(--color_gradients_2)',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

[dir] .categories-list ul li a.active{
    border-radius: 9px;
    background: var(--color_gradients_1);
}

[dir=ltr] .categories-list ul li a.active{
    background: linear-gradient(135deg, var(--color_gradients_1) 1%,var(--color_gradients_2) 100%);
}

[dir=rtl] .categories-list ul li a.active{
    background: linear-gradient(-135deg, var(--color_gradients_1) 1%,var(--color_gradients_2) 100%);
}


.sticky-section{
    transition: 0.3s;
}


[dir] .sticky-section{
    margin-top: 125px;
}

.menu-section-header span{
    font-size: 19px;
    color: #fff;
    display: inline-block;
}

[dir] .menu-section-header span{
    padding: 7px 25px !important;
    border-bottom: 1px solid #ff4b150f;
    border-radius: 9px;
}

[dir=ltr] .menu-section-header span{
    background: linear-gradient(135deg, var(--color_gradients_1) 1%,var(--color_gradients_2) 100%);
}

[dir=rtl] .menu-section-header span{
    background: linear-gradient(-135deg, var(--color_gradients_1) 1%,var(--color_gradients_2) 100%);
}
@media (min-width: 1200px) {
    .osahan-slider {
        //transform: translateY(190px);
    }
    [dir] .menu-section-header{
        margin-top: 81px;
    }
}


.login-select{
    font-size: 13px;
    min-height: 38px;
    width: auto;
}


[dir] .login-select{
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #ced4da;
    box-shadow: none !important;
    margin-top: 13px;
}
@media (min-width: 992px) {
    [dir=rtl] .navbar-expand-lg .navbar-nav{
        flex-direction: row-reverse;
    }
}

[dir=ltr] .osahan-account-page-left .nav-link {
    text-align: left;
}

[dir=rtl] .osahan-account-page-left .nav-link {
    text-align: right;
}
[dir] .order-list .btn {
    margin: 4px 4px;
}

.order-list .media-body p{
    display: flex;
    align-content: center;
}

[dir=ltr] .order-list .media-body p i, [dir=ltr] .order-list .media-body p span {
    margin-right: 5px;
}

[dir=rtl] .order-list .media-body p i, [dir=rtl] .order-list .media-body p span {
    margin-left: 5px;
}

.edit-profile-footer Button{
    flex: 1 1;
}

@media (min-width: 992px) {
    [dir=rtl] .navbar-expand-lg .navbar-collapse:not(.select-countries) {
        display: flex !important;
        flex-basis: auto;
        flex-grow: inherit;
    }
}
.cascade-input div{
    width: 100%;
    height: 100%;
}
[dir] .cascade-input div{
    margin: 0px;
}

[dir] .cascade-input > div{
    border-radius: 4px;
}

.cascade-input div input{
    width: 100%;
    height: 100%;
    font-size: 15px;
    line-height: 47px !important;
    color: #282828;
}

[dir] .cascade-input div input{
    padding: 0 12px;
    border-radius: 4px;
    border: 1px solid #fff;
}

[dir=ltr] .cascade-input div input{
    text-align: left;
}

[dir=rtl] .cascade-input div input{
    text-align: right;
}

.cascade-input div input:focus, .cascade-input div input:focus-visible, .cascade-input div input:focus-within{
    outline: none;
}

[dir] .cascade-input div input:focus, [dir] .cascade-input div input:focus-visible, [dir] .cascade-input div input:focus-within{
    border: 1px solid #fff;
}

.Cascade-module_dropdownMenuItem__2z87N {
    font-family: "Cairo", sans-serif !important;
}


[dir=ltr] .Cascade-module_dropdownMenuItem__2z87N > .Cascade-module_dropdownMenu__19WmY {
    left: 100% !important;
    right: inherit !important;
}


[dir=rtl] .Cascade-module_dropdownMenuItem__2z87N > .Cascade-module_dropdownMenu__19WmY {
    right: 100% !important;
    left: inherit !important;
}

.Cascade-module_withSubitem__3m2pc:after{
    display: none !important;
}

.product-modal-price{
    font-size: 19px;
    color: #015b6f;
}

[dir] .product-modal-price{
    text-align: center;
}
[dir=ltr] .dropdown-item {
    text-align: left;
}
[dir=rtl] .dropdown-item {
    text-align: right;
}

[dir] .text-center *{
    text-align: center !important;
}

[dir=ltr] .text-center input, [dir=ltr] .text-center textarea {
    text-align: left !important;
}

[dir=rtl] .text-center input, [dir=rtl] .text-center textarea {
    text-align: right !important;
}
[dir=ltr] input {
    text-align: left !important;
}
[dir=rtl] input {
    text-align: right !important;
}

[dir=ltr] table * {
    text-align: left;
}

[dir=rtl] table * {
    text-align: right;
}

[dir] .modal-footer  input.count-number-input, [dir] input.count-number-input{
    text-align: center !important;
}

.search-for-menu{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

[dir] .search-for-menu{
    padding: 13px 10px;
    border-radius: 5px;
}

.wish-list-btn{
    position: absolute;
    z-index: 9;
    top: 10px;
    font-size: 33px;
    color: #ffffff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

[dir] .wish-list-btn{
    border: none;
    padding: 6px;
    background-color: #015b6f82;
    border-radius: 4px;
}

[dir=ltr] .wish-list-btn{
    right: 10px;
}

[dir=rtl] .wish-list-btn{
    left: 10px;
}

.wish-list-btn i{
    font-size: 18px;
}

.top-nav{ /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color_gradients_1)', endColorstr='var(--color_gradients_2)',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

[dir] .top-nav{
    background: var(--color_gradients_1);
    padding: 0;
}

[dir=ltr] .top-nav{
    background: linear-gradient(135deg, var(--color_gradients_1) 1%,var(--color_gradients_2) 100%);
}

[dir=rtl] .top-nav{
    background: linear-gradient(-135deg, var(--color_gradients_1) 1%,var(--color_gradients_2) 100%);
}
.top-nav .follow-us-btn{
    width: 25px;
    height: 25px;
    color: var(--color_gradients_1);
    line-height: 35px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

[dir] .top-nav .navbar-expand{
    padding: 0;
}
@media (min-width: 991px) {
    .change-lang-btn, .navbar-expand-lg .navbar-collapse.select-countries {
        display: none  !important;
    }
}
@media (max-width: 991px) {
    .top-nav{
        display: none;
    }
    [dir=rtl] [dir=ltr] .dropdown-cart.nav-item.dropdown {
        margin-right: 0px !important;
    }
}
.status{
    display: flex;
    width: 100%;
    max-width: 500px;
    justify-content: space-between;
    align-items: center;
    height: 100px;
}
[dir] .status{
    margin: auto;
}
.status .status-point{
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}
.status .status-point span:first-child{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
[dir] .status .status-point span:first-child{
    background-color: #ddd;
    border-radius: 50%;
}

.status .status-point span.status-point-text{
    position: absolute;
    bottom: -27px;
    width: 130px;
    font-size: 14px;
}

[dir] .status .status-point span.status-point-text{
    text-align: center;
}
.status .status-line {
    width: 100%;
    height: 4px;
}
[dir] .status .status-line {
    background-color: #ddd;
}

[dir] .status .status-point.active span:first-child{
    border: 3px solid var(--header_bg_color);
}


[dir] .status .status-point.status-done span:first-child{
    background-color: var(--header_bg_color);
}

[dir] .status .status-line.status-done{
    background-color: var(--header_bg_color);
}
[dir] .btn-group.btn-group-toggle .btn{
    margin: 0 5px;
}

.scroll-div-position {
    position: relative;
    width: 100%;
    top: -110px;
    height: 0px;
}
[dir=ltr] .modal-header .close {
    margin: -1rem -1rem -1rem auto;
}
[dir=rtl] .modal-header .close {
    margin: -1rem auto -1rem -1rem;
}

.modal-open .modal {
    z-index: 99999999999999;
}
.modal-backdrop.show {
    z-index: 99999999999999;
}


[dir=ltr] #countries + .dropdown-menu{
    right: 0;
    left: inherit;
}

[dir=rtl] #countries + .dropdown-menu{
    right: inherit;
    left: inherit;
}
.tooltip {
    z-index: 99999999999999999;
}
[dir] .content.main-content{
    padding-top: 110px;
}

.search-box-map.search-box-map-address{
    bottom: inherit;
}

.search-box-map.search-box-map-address input{
    width: 100%;
    height: 50px;
    font-size: 15px;
    line-height: 47px !important;
    color: #282828;
}

[dir] .search-box-map.search-box-map-address input{
    border-radius: 4px;
    border-width: 0px;
    padding: 8px 10px;
}

.search-box-map.search-box-map-address input:focus, .search-box-map.search-box-map-address input:focus-visible, .search-box-map.search-box-map-address input:focus-within{
    outline: none;
}

[dir] .search-box-map.search-box-map-address input:focus, [dir] .search-box-map.search-box-map-address input:focus-visible, [dir] .search-box-map.search-box-map-address input:focus-within{
    border: 1px solid #fff;
}

[dir] .product-slider + .close{
    left: 6px;
    position: absolute;
    z-index: 9;
    background-color: #fff;
    padding: 0px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
[dir=rtl] .product-slider + .close{
    left: 30px;
}
.product-slider + .close span{
    position: absolute;
    top: 1px;
}
.empty-section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    flex-direction: column;
    color: #ddd;
}
.empty-section p{
    font-size: 26px;
    color: #ddd;
}
.flag{
    width: 25px;
    height: 15px;
    display: inline-block;

}
[dir] .flag{
    margin: 4px 5px 0 5px;

}
[dir=ltr] .flag{
    float: left;

}
[dir=rtl] .flag{
    float: right;

}
.branche-card .branche-card-map{
    overflow: hidden;
}
[dir] .branche-card .branche-card-map{
    border-radius: 5px;
}
[dir=ltr] .form-label{
    text-align: left;
}
[dir=rtl] .form-label{
    text-align: right;
}
[dir] input#inputPhone{
    text-align: left !important;
}
[dir] .form-control[name=code]{
    text-align: left !important;
}
[dir] .offer-dedicated-body-left{
    padding-bottom: 50px;
}
[dir] [data-rb-event-key='4'].active + .active{
    border-bottom: 0px;
}

.main-content > section.osahan-account-page{
    min-height: calc(100vh - 193px);
}

.main-content > .offer-dedicated-body{
    min-height: calc(100vh - 241px);
}
.flag-mob{
    width: 27px;
}
[dir] .flag-mob{
    margin: 0 5px;
}
@media (max-width: 991px) {
    [dir] .cascade-input div input{
        background-color: #ffffff;
        padding: 12px;
        border: 1px solid #fff;
        border-radius: 4px;
    }
}

[dir] .homepage-search-form-translate{
    -webkit-transform:  translateY(120px);
            transform:  translateY(120px);
}

@media (max-width: 575.98px) {
    .order-list .float-right.btn-actions {
        display: flex;
        flex-direction: column;
    }
    [dir] .osahan-track-order-detail{
        padding: 0 35px;
    }
    .status .status-point span.status-point-text{
        width: 75px;
        bottom: -50px;
    }

}
@media (max-width: 991px) {
    .homepage-search-block--small{
        height: 450px !important;
    }
    [dir] .homepage-search-block--small{
        padding-top: 170px !important;
        padding-bottom: 0 !important;
    }
}

@media (max-width: 1200px) {
    [dir] .homepage-search-form-translate{
        -webkit-transform:  translateY(0px);
                transform:  translateY(0px);
    }
}

.loading-wrap-gif{
    display: flex;
    justify-content: center;
    align-items: center;
}

[dir] .loading-wrap-gif{
    background-color: #27262b;
}

[dir] .loading-wrap-gif-light{
    background-color: #ffffff;
}

.loading-wrap-gif img{
    width: 400px;
    max-width: 60vw;
}

.quick-bite-title{
    font-size: 18px;
}

.p-one-line {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
    max-width: 90%;
}
.menu-list .media-body{
    width: calc(100% - 86px);
}
@media (max-width: 991.98px) {
    #countries + .dropdown-menu {
        position: unset;
        width: 100%;
    }
    [dir=ltr] #countries + .dropdown-menu {
        float: left;
    }
    [dir=rtl] #countries + .dropdown-menu {
        float: right;
    }
}

.lb-container {
    z-index: 99999999999;
}


.info-modal-btn {
    z-index: 9999999999;
    position: fixed;
    top: 80px;
    height: 45px;
    font-size: 16px;
    color: #ffffff
}


[dir] .info-modal-btn {
    border: 0px;
    padding: 0px;
    border-radius: 4px;
    background-color: var(--header_bg_color)
}


[dir=ltr] .info-modal-btn {
    right: 40px
}


[dir=rtl] .info-modal-btn {
    left: 40px
}
[dir] .info-modal-btn span{
    padding: 10px;
}

.info-modal-btn i{
    color: var(--header_bg_color);
    height: 45px;
    display: inline-flex;
    align-self: center;
    justify-content: center;
    line-height: 23px;
}

[dir] .info-modal-btn i{
    padding: 10px;
    background-color: #ffffff;
}

[dir=ltr] .info-modal-btn i{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

[dir=rtl] .info-modal-btn i{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

[dir] .footer-1{
    background: transparent;
    margin-top: 28px;
}
[dir] .footer-1-list{
    margin: 0;
    padding: 0 !important;
}
.footer-1 .powered-by-section{
    display: flex;
    align-items: end;
    justify-content: center;
}
[dir] .footer-1 .powered-by-section{
    padding-bottom: 13px !important;
}

.footer-1 p.powered-by{
    color: #ffffff;
}
.footer-1 p.powered-by a{
    text-decoration: underline !important;
    font-size: 18px;
    color: var(--button_color);
}
.footer-1 p.powered-by i{
    font-size: 18px;
}

.footer-1 p.download-app-now{
    color: #ffffff;
    font-size: 16px;
}

[dir] .footer-1 p.download-app-now{
    margin-bottom: 13px !important;
    padding: 0 7px;
}

[dir=ltr] .footer-1 p.download-app-now{
    text-align: left;
}

[dir=rtl] .footer-1 p.download-app-now{
    text-align: right;
}

.store-links{
    width: 310px;
}

[dir] .store-links{
    margin: auto;
}

.store-links a{
    display: inline-block;
}

[dir] .store-links a{
    margin: 0 5px 13px 5px;
}

.store-links a img{
    width: 145px;
}

.email-warning, .ad-block-show{
    font-size: 14px;
    position: relative;
}

[dir] .email-warning, [dir] .ad-block-show{
    background-color: #fffb00;
    padding: 14px;
    text-align: center;
}
.email-warning button, .ad-block-show button{
    position: absolute;
    top: 0;
    width: 50px;
}
[dir] .email-warning button, [dir] .ad-block-show button{
    margin-top: 5px;
}
[dir=ltr] .email-warning button, [dir=ltr] .ad-block-show button{
    right: 0;
}
[dir=rtl] .email-warning button, [dir=rtl] .ad-block-show button{
    left: 0;
}

[dir] .ad-block-show button{
    margin-top: 10px;
}

.ad-block-show .container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

[dir] .ad-block-show .container{
    margin: auto;
}
[dir] .ad-block-show .container img{
    width: 115px;
    margin: 0 12px;
}


[dir] [dir] .ad-block-show .container img.ad-block-show-right{
    padding-left: 12px;
    border-left: 1px solid #a29f9f;
}

[dir] [dir] .ad-block-show .container img.ad-block-show-left{
    padding-right: 12px;
    border-right: 1px solid #a29f9f;
}


@media (max-width: 768px) {
    [dir] [dir] .ad-block-show .container{
        flex-direction: column-reverse;
    }

    [dir] [dir] .ad-block-show .container img.ad-block-show-left, [dir] [dir] .ad-block-show .container img.ad-block-show-right{
        border-right: 0px solid #a29f9f;
        border-left: 0px solid #a29f9f;
        padding-right: 0px;
        padding-left: 0px;
        margin-bottom: 10px;
    }
}

.sale-price {
    color: #ff7373;
    position: relative;
}

[dir] .sale-price {
    margin: 0 5px;
}
.sale-price i {
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
}
[dir] .sale-price i {
    background-color: #ff7373;
}

.content .footer-1 p{
    color: var(--button_color);
    font-size: 20px;
}
.homepage-search-block .footer-1 p, .homepage-search-block  .footer-1 p.powered-by a{
    color: #dddddd;
}
.show-more-btn{
    color: #dadada;
    text-decoration: underline !important;
}

.show-more-btn:hover{
    color: #e8e8e8;
}
.required-badge{
    font-size: 11px;
    color: var(--color_gradients_1)
}
[dir] .required-badge{
    border: 1px solid var(--color_gradients_1);
    padding: 5px;
    border-radius: 4px
}

.btn-info{
    color: var(--button_color_text) !important;
    -webkit-filter: saturate(0.7) !important;
            filter: saturate(0.7) !important;
    opacity: 0.8 !important;
}

[dir] .btn-info{
    background-color: var(--button_color) !important;
    border-color: var(--button_color) !important;
}

.btn-info.active{
    color: var(--button_color_text) !important;
    -webkit-filter: saturate(1) !important;
            filter: saturate(1) !important;
    opacity: 1 !important;
}

[dir] .btn-info.active{
    background-color: var(--button_color) !important;
    border-color: var(--button_color) !important;
}

@media (min-width: 991.98px) {
    .info-desc-modal{
        max-width: 338px;
        display: block;
        top: 70px;
        min-height: 50vh;
    }
    [dir=ltr] .info-desc-modal{
        margin-right: 40px;
    }
    [dir=rtl] .info-desc-modal{
        margin-left: 40px;
    }
    .info-desc-modal-backdrop{
        display: none;
    }
    .info-desc-modal-backdrop+.modal{
        top: 50px;
    }
}
@media (max-width: 575.98px) {
    [dir] .footer-1 p.download-app-now{
        text-align: center;
    }
    .email-warning {
        font-size: 11px;
    }
    [dir] .email-warning {
        background-color: #fffb00;
    }
    [dir=ltr] .email-warning {
        padding: 2px 8px 2px 47px;
        text-align: right;
    }
    [dir=rtl] .email-warning {
        padding: 2px 47px 2px 8px;
        text-align: left;
    }
    [dir] .email-warning button{
        margin-top: 0;
    }
    .osahan-category-item p{
        flex-direction: column;
    }

    .osahan-category-item p .sale-price{
        font-size: 11px !important;
    }
}


.owl-carousel:not(.homepage-ad) .owl-stage {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    align-content: stretch;
}

.owl-carousel:not(.homepage-ad) .owl-item {
    width: auto;
    height: 100%;
}

.owl-carousel:not(.homepage-ad) .owl-item .item {
    width: auto;
    height: 184px;
}
.owl-carousel:not(.homepage-ad) .osahan-category-item {
    height: 100%;
}

.osahan-track-order-detail-title{
    font-size: 20px;
    color: var(--header_bg_color)
}
[dir=ltr] .product-info .p-one-line{
    float: left;
}
[dir=rtl] .product-info .p-one-line{
    float: right;
}

@media (min-width: 768px) {
    [dir] .osahan-category-item h6 {
        margin-top: 10px;
    }
}

@media (max-width: 768px) {
    [dir] .osahan-category-item h6.mt-10 {
        margin-top: 10px;
    }
}

.owl-carousel.owl-loaded{
    display: block !important;
}

.search-box-map-modal{
    width: 100%;
    height: 450px;

}
.search-box-map-modal #search-box-map{
    width: 100%;
    height: 450px;
}

.search-box-map-modal-footer Button{
    flex: 1 1;
}
[dir] .center-map-btn{
    display: block;
    border: 0px;
    margin: 0px;
    padding: 0px;
    text-transform: none;
    -webkit-appearance: none;
            appearance: none;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow: hidden;
    width: 40px;
    height: 40px;

    position: absolute;
    z-index: 9;
    right: 10px;
    bottom: 112px;
    top: inherit;

    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    border-radius: 2px;
    background-color: rgb(255, 255, 255);

    color: #666;
    font-size: 20px;
}

[dir] .center-map-btn:hover{
    color: #2f2f2f;
}

.search-in-map{
    position: absolute;
    top: 0;
    height: 70px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}

[dir=ltr] .search-in-map{
    right: 0;
    left: 0;
}

[dir=rtl] .search-in-map{
    left: 0;
    right: 0;
}


.search-in-map > div {
    width: 90%;
}

[dir=ltr] .alert{
    text-align: left;
}

[dir=rtl] .alert{
    text-align: right;
}

.osahan-payment .btn-group.btn-group-toggle .btn {
    font-size: 18px;
}

.order-card-total-paid{
    font-size: 16px;
}

[dir] .order-card-total-paid{
    margin-top: 19px;
}
[dir] .date-format{
    direction: ltr;
}

[dir=ltr] .date-format{
    float: right;
}

[dir=rtl] .date-format{
    float: left;
}

[dir] .offer-dedicated-body.offer-dedicated-body-list {
    margin-bottom: 100px !important;
}

.cart-mobile{
    display: none;
}

@media (max-width: 991.98px) {
    .cart-mobile{
        display: block;
    }
    .cart-mobile * {
        color: #ffffff;
    }
    [dir] .cart-mobile a {
        border-bottom-width: 0 !important;
    }
    .cart-mobile .dropdown-menu {
        position: fixed;
        z-index: 99999999999;
        top: 103px;
    }
    [dir=ltr] .cart-mobile .dropdown-menu {
        right: 0;
        left: 0 !important;
    }
    [dir=rtl] .cart-mobile .dropdown-menu {
        left: 0;
        right: 0 !important;
    }
}
@media (max-width: 575.98px) {
    .cart-mobile .dropdown-menu {
        top: 85px;
    }
}

@media (max-width: 349px) {
    .cart-mobile .dropdown-menu {
        top: 85px;
    }
    .dropdown-cart-top {
        min-width: 100%;
    }
}


