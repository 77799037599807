
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

[dir] .fade-enter {
  transform: translateY(10px);
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in, transform 270ms ease-in;
}

[dir] .fade-enter.fade-enter-active {
  transform: translateY(0);
}

